import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import List from '../../components/CoreComponents/List';
import { DrilldownMappingMeaning, getDrilldownType } from '../../Constants/Consts';
import { getByPath } from '../../Helpers/Functions';
import { RootState } from '../../store/store';
import { setBackClickHandler, setTaskDisplayedPath } from '../../store/Utilities';
import Breadcrumb from '../../components/CoreComponents/Breadcrumb';
import { RiArrowRightSLine } from 'react-icons/ri';
import { FaSearch } from 'react-icons/fa';
import { MdOutlineClear } from 'react-icons/md';

export default function TasksDD() {
    const { ChecklistsObject } = useSelector((state: RootState) => state.SequenceObject);
    const { SelectedUser } = useSelector((state: RootState) => state.SelectedUser);
    const { taskDisplayedPath } = useSelector((state: RootState) => state.Utilities);
    const [displayedObject, setDisplayedObject] = useState({})
    const drilldownType = getDrilldownType(SelectedUser.disciplineId)
    const DrilldownMappingObject = DrilldownMappingMeaning[drilldownType]

    const isPathToShowTasks = (path) => path.split('.').length === DrilldownMappingObject.pathStepsToShowTasks
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        if (isPathToShowTasks(taskDisplayedPath)) {
            const f = encodeURIComponent(taskDisplayedPath)
            navigate(`/CheckListDD/${f}`)
        }
        const obj = getByPath(taskDisplayedPath, ChecklistsObject)
        setDisplayedObject(obj)
    }, [taskDisplayedPath])
    useEffect(() => {
        dispatch(setBackClickHandler(() => {
            const pathArray = taskDisplayedPath.split('.')
            if (!pathArray.length || !pathArray.some(s => !!s))
                navigate(-1)
            pathArray.pop()
            const previousPath = pathArray.join('.')
            dispatch(setTaskDisplayedPath(previousPath))
        }))
        return () => {
            dispatch(setBackClickHandler(null))
        }
    }, [taskDisplayedPath])

    const breadcrumbTrail = [
        { label: SelectedUser.projectNumber },
        { label: ' - ' },
        { label: SelectedUser.project },
        { label: <RiArrowRightSLine />, icon: true },
        { label: SelectedUser.discipline },
        { label: <RiArrowRightSLine />, icon: true },
        { label: DrilldownMappingObject.dd01 },
    ];
    const [searchTerm, setSearchTerm] = useState("")
    const initialArray = Object.keys(displayedObject)
    const [filteredObjectData, setFilteredObjectData] = useState(initialArray)
    useEffect(() => {
        if (searchTerm) setFilteredObjectData(initialArray.filter(k => k.toLowerCase().includes(searchTerm.toLowerCase())))
        else setFilteredObjectData(initialArray)
    }, [searchTerm, displayedObject])
    return (
        <div className="ml-2">
            <Breadcrumb trail={breadcrumbTrail} showIndices={[2, 3, 4, 5, 6]} />
            <div className='flex'>
                <div className="border rounded-lg">
                    <span className="flex items-center px-2 py-3 text-sm font-medium bg-gray-50 dark:bg-gray-900 dark:text-gray-200">
                        <FaSearch />
                        <span className="ml-2 hidden md:inline-block">Search</span>
                    </span>
                </div>
                <div className="flex border rounded-lg border-1 w-full">
                    <input value={searchTerm} onChange={event => setSearchTerm(event.target.value)}
                        type="text" placeholder="Search inspection task" className="px-2 w-full" />
                    <div className="border border-black rounded-lg bg-gray-50 py-1" hidden={searchTerm === ""}>
                        <button type="button" className="py-3" title="Clear" onClick={() => setSearchTerm("")}  >
                            <MdOutlineClear />
                        </button>
                    </div>
                </div>
            </div>
            <List<string>
                data={filteredObjectData}
                renderChildItem={(item) => (props: {}) =>
                    <div {...props}>{item}</div>}
                onClick={(selectedKey) => {
                    const nextPath = taskDisplayedPath ? taskDisplayedPath + '.' + selectedKey : selectedKey
                    // if we reached the units checklist onjects 
                    if (isPathToShowTasks(nextPath)) {
                        const f = encodeURIComponent(nextPath)
                        navigate(`/CheckListDD/${f}`)
                        return
                    }
                    dispatch(setTaskDisplayedPath(nextPath))
                    const obj = getByPath(nextPath, ChecklistsObject)
                    if (obj) setDisplayedObject(obj)
                }}
                isItemDisabled={false}
            />
        </div>
    )
}
