import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PiSealWarning } from "react-icons/pi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import SectionHeader from "../../components/CoreComponents/Details/SectionHeader.tsx";
import Loader from "../../components/CoreComponents/Loader.tsx";
import FormDDL from "../../components/CoreComponents/ReactFormComponents/FormDDL.tsx";
import FormInput from "../../components/CoreComponents/ReactFormComponents/FormInput.tsx";
import { getDrilldownType, URLs } from "../../Constants/Consts.ts";
import { fetchApi, splitOnCapitals } from "../../Helpers/Functions.ts";
import { RootState } from "../../store/store.ts";
import { getProjectText } from "../../types/types.ts";
import { detailsSchema } from "../../types/Unit/AdHocUnitSchema.ts";

export default function CreateAdHocUnit() {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue
    } = useForm({ resolver: zodResolver(detailsSchema) });

    const { SelectedUser } = useSelector((state: RootState) => state.SelectedUser)
    const { DrilldownMappingsAPIResponse } = useSelector((state: RootState) => state.SequenceObject);
    const { projects } = useSelector((state: RootState) => state.Project)
    const [loading, setLoading] = useState(false);
    const drilldownType = getDrilldownType(SelectedUser.disciplineId)
    const DrilldownMappingMeaning = DrilldownMappingsAPIResponse.find(d => d.drilldownTypeId === drilldownType)
    const [selectedModel, setselectedModel] = useState("")
    const [filteredModels, setFilteredModels] = useState([])
    useEffect(() => {
        (async () => {
            setLoading(true);
            const response = await fetchApi(URLs.getModelsPerDiscipline(SelectedUser.disciplineId))
            console.log("response", response);
            if (response.status === 200) {
                const data = await response.json()
                setFilteredModels(data.map(({ modelNumber, modelDescription }) => `${modelNumber} - ${modelDescription}`))
                setLoading(false);
            } else
                setLoading(false);
        })()
    }, [])


    useEffect(() => {
        // in case of controls
        if (drilldownType === 2) {
            const ChecklistTypeArray = selectedModel.split("-")
            const ChecklistType = ChecklistTypeArray[0]
            if (ChecklistTypeArray.length === 2) setValue("dd02", `${ChecklistType} Checklist`)
            else setValue("dd02", ``)
        }
    }, [selectedModel])

    useEffect(() => {
        setValue("ProjectNumber", SelectedUser.projectNumber)
    }, [SelectedUser]);

    const onSubmit = async data => {
        setLoading(true);
        await fetchApi(URLs.postAdhocChecklistTarget(), {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(() => navigate(-1))
        setLoading(false);
    }

    return (
        loading ? <Loader loaders={{ isModelsLoading: loading }} /> :
            <div className='max-w-screen-md'>
                <div className='mt-8 mb-8'>
                    <SectionHeader title='Add Adhoc Checklist Item' />
                </div>
                <div className='flex items-center mb-5 '>
                    <PiSealWarning className='mr-2 text-uniteOrange' />
                    <h2>Checklist could take up to 15min to show in UNITE.</h2>
                </div>
                <div>
                    <form className='relative' onSubmit={handleSubmit(onSubmit)}>
                        <FormDDL
                            required={true}
                            options={
                                [...projects]
                                    .sort((a, b) => a.projectName.localeCompare(b.projectName))
                                    .map(i => ({ text: getProjectText(i), value: i.projectNumber }))
                            }
                            control={control}
                            errors={errors}
                            defaultValue={SelectedUser.projectNumber}
                            inputKey={"ProjectNumber"}
                            label={"Project Selection"}
                            name={"ProjectNumber"}
                        />
                        <FormInput
                            required={true}
                            errors={errors}
                            defaultValue=""
                            inputKey={"dd01"}
                            registeredProps={register("dd01")}
                            label={splitOnCapitals(DrilldownMappingMeaning?.dd01)}
                            placeholder={''}
                        />
                        <FormInput
                            required={true}
                            errors={errors}
                            defaultValue=""
                            disabled={drilldownType === 2}
                            inputKey={"dd02"}
                            registeredProps={register("dd02")}
                            label={splitOnCapitals(DrilldownMappingMeaning?.dd02)}
                            placeholder={''}
                        />
                        {/* {!!DrilldownMappingMeaning?.dd03 && <FormInput
                            required={true}
                            errors={errors}
                            defaultValue=""
                            inputKey={"dd03"}
                            registeredProps={register("dd03")}
                            label={splitOnCapitals(DrilldownMappingMeaning?.dd03)}
                            placeholder={''}
                        />} */}
                        <FormDDL
                            required={true}
                            options={
                                filteredModels
                                    .sort((a, b) => a.localeCompare(b))
                                    .map(i => ({ text: i, value: i }))
                            }
                            control={control}
                            errors={errors}
                            defaultValue={undefined}
                            inputKey={"ModelNumber"}
                            label={splitOnCapitals("ModelNumber")}
                            name={"ModelNumber"}
                            onChangeEvent={selectedValue => {
                                setselectedModel(selectedValue)
                            }}
                        />
                        <div className='flex items-center mt-16'>
                            <button
                                className='absolute left-0 mt-3 text-gray-900 bg-gray-500 dark:text-gray-900 dark:bg-gray-500'
                                onClick={() => navigate(-1)}>Cancel
                            </button>
                            <button type='submit' disabled={false}
                                className={twMerge("absolute right-0 text-white ", true ?
                                    "bg-orange-1000 dark:text-gray-800 dark:bg-orange-1000" :
                                    "text-gray-900 bg-gray-500 dark:text-gray-900 dark:bg-gray-500")}>
                                Submit
                            </button>
                        </div>
                        <div className='flex items-center justify-center mt-10 p-12 '>
                            <PiSealWarning className='mr-2 text-uniteOrange' />
                            <h2>In order to add a unit checklist item, you must be connected to the internet.
                            </h2>
                        </div>
                    </form>
                </div>
            </div>
    );
}
