import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Status } from "../../../Constants/Consts.ts";
import { RootState } from "../../../store/store";
import { ROLES } from "../../../utils/roles.ts";
import { DownloadUserRelatedData } from "../../DownloadManager/DownloadManager.tsx";
import SyncManager from "../../SyncManager/SyncManager";

// UI ELEMENTS
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import { BsPinMap } from "react-icons/bs";
import { FaLinkSlash, FaRegCircleUser } from "react-icons/fa6";
import { GoChecklist } from "react-icons/go";
import { HiExternalLink } from "react-icons/hi";
import { IoIosAddCircleOutline, IoIosArrowDropdown } from "react-icons/io";
import { IoBusiness } from "react-icons/io5";
import { MdOutlineHomeWork, MdOutlinePostAdd, MdSecurity } from "react-icons/md";
import { PiClockCountdownFill, PiUsersThreeDuotone } from "react-icons/pi";
import { RxReload } from "react-icons/rx";
import { VscChecklist, VscNotebookTemplate } from "react-icons/vsc";
import LogoutButton from "../../User/LogoutButton.tsx";
import Loader from "../Loader.tsx";
import andriodIcon from "/android-chrome-512x512.png";

const Navbar = () => {
    const [nav, setNav] = useState(false);
    const navigate = useNavigate();
    const { SelectedUser } = useSelector(
        (state: RootState) => state.SelectedUser
    );
    const { backClickHandler } = useSelector(
        (state: RootState) => state.Utilities
    );

    const roleCheck = (userRole: string, allowedRoles: string[]) => {
        return allowedRoles.includes(userRole);
    };

    const filterMenuItems = (id) => {
        return menuItems.filter(item =>
            item.id === id && roleCheck(SelectedUser?.role, item.roles));
    };

    const menuRef = useRef<HTMLDivElement>(null);
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setNav(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    // ACCESS CODE
    const isRoleIt = SelectedUser.role === 'IT';
    const isRoleSubcontractor = SelectedUser.role === 'External';
    const isDataMissing = !isRoleIt && !SelectedUser.projectNumber && !SelectedUser.discipline;
    const hasAccess = isRoleIt || !isDataMissing || isRoleSubcontractor;

    // ALLOWED TO CHANGE ROLES

    const allowedEmails = [
        "wihl.rodriguez@kiongroup.com",
        "tamara.kilcrease@kiongroup.com",
        "wesley.stasik@kiongroup.com",
        "mustafa.elaleem.ext@kiongroup.com",
        "christoph.wohlfahrt@kiongroup.com"
    ];

    const userEmailLowerCase = SelectedUser?.kionEmail?.toLowerCase();
    const canSeeRoleChangeLink = allowedEmails.map(email => email.toLowerCase()).includes(userEmailLowerCase);
    const userMenuText = SelectedUser?.role === ROLES.UNITE_User || SelectedUser?.role === ROLES.dematic_d_unite ? "Edit My Assignment" : "All Users (View/Edit)";
    const userMenuPath = SelectedUser?.role === ROLES.UNITE_User || SelectedUser?.role === ROLES.dematic_d_unite ? `/AllUsers/Edit/${SelectedUser?.uuid}` : "/AllUsers";
    const menuItems = [
        {
            id: 1,
            icon: <MdOutlineHomeWork size={20} className="mr-4 text-orange-1000" />,
            text: "Home",
            path: "/",
            roles: [ROLES.UNITE_AdminUser, ROLES.UNITE_SuperAdmin, ROLES.UNITE_User, ROLES.dematic_d_unite, ROLES.UNITE_IT],
        },
        {
            id: 1,
            icon: <VscChecklist size={20} className="mr-4 text-orange-1000" />,
            text: "Checklists",
            // path: "/Sequences", OLD PATH
            path: "/TasksDD", // NEW PATH
            roles: [ROLES.UNITE_AdminUser, ROLES.UNITE_SuperAdmin, ROLES.UNITE_User, ROLES.dematic_d_unite],
        },
        {
            id: 1,
            icon: <GoChecklist size={20} className="mr-4 text-orange-1000" />,
            text: "Punch Lists",
            path: "/PunchList",
            roles: [ROLES.UNITE_AdminUser, ROLES.UNITE_SuperAdmin, ROLES.UNITE_User, ROLES.dematic_d_unite],
        },
        {
            id: 1,
            icon: <MdOutlinePostAdd size={20} className="mr-4 text-orange-1000" />,
            text: "Add New Punch List Issue",
            path: "/PunchList/Form/Discipline/1",
            roles: [ROLES.UNITE_AdminUser, ROLES.UNITE_SuperAdmin, ROLES.UNITE_User, ROLES.dematic_d_unite],
        },
        {
            id: 1,
            icon: <RxReload size={20} className="mr-4 text-orange-1000" />,
            text: "Ready For Re-Inspection",
            path: `/PunchListDiscipline/${SelectedUser.disciplineId}/view/${Status.ReadyForReInspection}`,
            roles: [ROLES.UNITE_AdminUser, ROLES.UNITE_SuperAdmin, ROLES.UNITE_User, ROLES.dematic_d_unite],
        },
        {
            id: 1,
            icon: <BsPinMap size={20} className="mr-4 text-orange-1000" />,
            text: "Maps / Drawings",
            path: `/DrawingLayouts`,
            roles: [ROLES.UNITE_AdminUser, ROLES.UNITE_SuperAdmin, ROLES.UNITE_User, ROLES.dematic_d_unite],
        },
        {
            id: 1,
            icon: <IoIosAddCircleOutline size={20} className="mr-4 text-orange-1000" />,
            text: "Adhoc Checklist Item",
            path: "/AdHocUnits",
            roles: [ROLES.UNITE_AdminUser, ROLES.UNITE_SuperAdmin, ROLES.UNITE_User, ROLES.dematic_d_unite],
        },
        {
            id: 1,
            icon: <PiUsersThreeDuotone size={20} className="mr-4" />,
            text: userMenuText,
            path: userMenuPath,
            roles: [ROLES.UNITE_AdminUser, ROLES.UNITE_SuperAdmin, ROLES.UNITE_User, ROLES.dematic_d_unite, ROLES.UNITE_IT],
        },
        {
            id: 2,
            icon: <HiExternalLink size={20} className="mr-2" />,
            text: "Training (SharePoint)",
            link: 'https://kionnam.sharepoint.com/sites/DematicUnite/SitePages/Training.aspx',
            roles: [ROLES.UNITE_SuperAdmin, ROLES.UNITE_SuperAdmin, ROLES.UNITE_User, ROLES.dematic_d_unite, ROLES.UNITE_IT],
        },
        {
            id: 2,
            icon: <PiClockCountdownFill size={20} className="mr-2" />,
            text: "Pending Sync Items",
            path: "/PendingSyncItems",
            roles: [ROLES.UNITE_SuperAdmin, ROLES.UNITE_SuperAdmin, ROLES.UNITE_User, ROLES.dematic_d_unite],
        },

        // COMING SOON

        {
            id: 5,
            icon: <VscNotebookTemplate size={18} className="mr-4" />,
            text: "UNITE Update Release Notes",
            path: "/",
            roles: [ROLES.UNITE_AdminUser, ROLES.UNITE_SuperAdmin, ROLES.UNITE_User, ROLES.dematic_d_unite],
        },
        {
            id: 5,
            icon: <IoBusiness size={18} className="mr-4" />,
            text: "Projects",
            path: "/",
            roles: [ROLES.UNITE_AdminUser, ROLES.UNITE_SuperAdmin, ROLES.UNITE_User, ROLES.dematic_d_unite],
        },
        {
            id: 5,
            icon: <MdSecurity size={18} className="mr-4" />,
            text: "AD Access Levels",
            path: "/",
            roles: [ROLES.UNITE_AdminUser, ROLES.UNITE_SuperAdmin, ROLES.UNITE_User, ROLES.dematic_d_unite],
        },
        {
            id: 5,
            icon: <FaLinkSlash size={18} className="mr-4" />,
            text: "Status Table",
            path: "/",
            roles: [ROLES.UNITE_AdminUser, ROLES.UNITE_SuperAdmin, ROLES.UNITE_User, ROLES.dematic_d_unite],
        },
        {
            id: 5,
            icon: <FaLinkSlash size={18} className="mr-4" />,
            text: "Discipline Table",
            path: "/",
            roles: [ROLES.UNITE_AdminUser, ROLES.UNITE_SuperAdmin, ROLES.UNITE_User, ROLES.dematic_d_unite],
        },
        {
            id: 5,
            icon: <FaLinkSlash size={18} className="mr-4" />,
            text: "Changelog Type Table",
            path: "/",
            roles: [ROLES.UNITE_AdminUser, ROLES.UNITE_SuperAdmin, ROLES.UNITE_User, ROLES.dematic_d_unite],
        }
    ];

    if (canSeeRoleChangeLink) {
        menuItems.push({
            id: 2,
            icon: <MdSecurity size={18} className="mr-3" />,
            text: "Change User Role",
            path: "/change-user-role",
            roles: [ROLES.UNITE_AdminUser, ROLES.UNITE_SuperAdmin, ROLES.UNITE_User, ROLES.dematic_d_unite, ROLES.UNITE_IT],
        });
    }

    const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);
    const loaders = DownloadUserRelatedData();
    const {
        isProjectsLoading, isPunchlistLoading, isTasksLoading, isDisciplineLoading
        , isIssuesLoading, isStatusLoading, isCategoriesLoading, isLogsLoading
        , isLogsTypesLoading, isCommentsLoading, isUsersLoading, isFilesLoading
        , isAdHocUnitsLoading, isModelsLoading, isRelatedChecklistDetailsLoading, isPunchlistImagesLoading
    } = loaders
    const isLoading =
        isProjectsLoading || isPunchlistLoading || isTasksLoading || isDisciplineLoading
        || isIssuesLoading || isStatusLoading || isCategoriesLoading || isLogsLoading
        || isLogsTypesLoading || isCommentsLoading || isUsersLoading || isFilesLoading
        || isAdHocUnitsLoading || isModelsLoading || isRelatedChecklistDetailsLoading || isPunchlistImagesLoading

    return (
        <>
            {isLoading ? <Loader loaders={loaders} /> :
                <div className='bg-white dark:bg-gray-1000 p-2.5'>
                    <div className="flex justify-between items-center gap-4">
                        {/* Hamburger icon and UNITE Logo */}
                        {hasAccess ? < div className="flex items-center gap-4">
                            <div onClick={(e) => { e.stopPropagation(); setNav(true); }} className="cursor-pointer">
                                <AiOutlineMenu size={30} />
                            </div>
                            <h2 onClick={() => navigate("/")} className="text-2xl flex items-center cursor-pointer">
                                <img src={andriodIcon} alt="Logo" className="w-8 h-8 mr-2 hidden md:inline-block" />
                                <span className="font-bold hidden md:inline-block">UNITE</span>
                            </h2>
                            {/* Back arrow component */}
                            <div>
                                <BiArrowBack className="cursor-pointer scale-150" title="Back" onClick={() => backClickHandler ? backClickHandler() : navigate(-1)} />
                            </div>
                        </div> : <div></div>}
                        {/* SyncManager and logout components */}
                        <div className="flex items-center gap-10 ">
                            {hasAccess &&
                                <>
                                    <SyncManager />
                                    {/* <Downloaded /> */}
                                </>
                            }
                            <LogoutButton />
                            <div className="absolute right-3">
                            </div>
                        </div>
                    </div>
                    {nav ? (

                        <div className="bg-black/80 fixed w-full h-screen z-10 top-0 left-0"></div>

                    ) : (
                        ""
                    )}

                    {/* Side nav menu */}

                    <div>
                        <div
                            ref={menuRef}
                            className={
                                nav
                                    ? "fixed top-0 left-0 w-[300px] h-screen bg-white z-10 duration-300 dark:bg-gray-1000"
                                    : "fixed top-0 left-[-100%] w-[300px] h-screen bg-white z-10 duration-300 dark:bg-gray-1000"
                            }
                        >
                            <AiOutlineClose onClick={() => setNav(!nav)} size={30} className="absolute right-4 top-4 cursor-pointer mt-2" />
                            <h2 className="text-2xl p-4 flex items-center">
                                <img src={andriodIcon} alt="Logo" className="w-8 h-8 mr-2 mt-2" />
                                <span className="font-bold text-black dark:text-white">UNITE</span>
                            </h2>

                            {/* Menu Items ID = 1 */}

                            <nav className="relative">
                                <ul className="flex flex-col p-4">
                                    {filterMenuItems(1).map(({ icon, text, path }, index) => (
                                        <div key={index} className="py-2 group" onClick={() => setNav(false)}>
                                            <Link to={path} className="items-center text-sm flex cursor-pointer mx-auto p-1 relative">
                                                {icon} {text}
                                                <div className="absolute right-0 top-0 h-full w-1 bg-orange-1000 opacity-0 group-hover:opacity-100 transition-opacity duration-100"></div>
                                            </Link>
                                            {text === "All Users (View/Edit)" && !roleCheck(SelectedUser?.role, [ROLES.UNITE_User, ROLES.dematic_d_unite, ROLES.UNITE_IT]) && (
                                                <div className="pl-4 py-2 group2">
                                                    <Link to={`/AllUsers/Edit/${SelectedUser?.uuid}`} className="items-center text-sm flex cursor-pointer mx-auto p-1">
                                                        <FaRegCircleUser size={16} className="mr-4 text-orange-1000" /> My Assignment
                                                        <div className="absolute right-0 top-0 h-full w-1 bg-orange-1000 opacity-0 group2-hover:opacity-100 transition-opacity duration-100"></div>
                                                    </Link>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </ul>
                            </nav>

                            <hr className="p-0" />

                            {/* Admin Menu Items ID = 2 */}

                            <nav className="relative">
                                <ul className="flex flex-col p-4 text-black dark:text-white">
                                    <div className="py-2">
                                        <div
                                            className="text-sm flex mx-auto p-1 items-center cursor-pointer"
                                            onClick={() => setIsSubMenuVisible(!isSubMenuVisible)}
                                        >
                                            <IoIosArrowDropdown size={18} className="mr-2" /> Admin & External Links
                                        </div>
                                    </div>
                                    {isSubMenuVisible &&
                                        filterMenuItems(2).map(({ id, icon, text, path, link }) => (
                                            <div key={id} className="pl-4 mb-1" onClick={() => setNav(false)}>
                                                {path ? (
                                                    <Link to={path} className="text-sm flex mx-auto p-1 items-center">
                                                        {icon} {text}
                                                    </Link>
                                                ) : (
                                                    <a href={link} target="_blank" rel="noopener noreferrer" className="text-sm flex mx-auto p-1 items-center">
                                                        {icon} {text}
                                                    </a>
                                                )}
                                            </div>
                                        ))}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div >
            }
        </>
    );
};

export default Navbar;