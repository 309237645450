import { FaPlusSquare } from "react-icons/fa";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store.ts";
import List from "../../components/CoreComponents/List.tsx";
import { IoBusinessOutline } from "react-icons/io5";
import { getProjectText } from "../../types/types.ts";
import { useNavigate } from "react-router-dom";

export default function AllAdHocUnits() {
    const navigate = useNavigate();

    const { projects } = useSelector((state: RootState) => state.Project);
    const { ProjectAdHocUnitItem } = useSelector((state: RootState) => state.AdHocUnit);

    const filteredProjectAdHocUnitItem = Object.entries(ProjectAdHocUnitItem)
        .reduce((acc, [project, items]) => {
            if (items.length !== 0 && ProjectAdHocUnitItem[project]) {
                acc[project] = ProjectAdHocUnitItem[project];
            }
            return acc;
        }, {});

    return (
        <>
            <div className="max-w-screen-lg">
                <div className={'flex items-center'}>
                    <div className='w-full text-xl font-semibold uppercase text-center mt-4 mb-4'>AdHoc Items</div>
                    <button
                        type="button"
                        className="bg-[#fdb419] float-right whitespace-nowrap"
                        title="Add Unit"
                        onClick={() => navigate(`/AdHocUnits/Create`)}>
                        <span className="flex gap-1 items-center text-sm font-medium text-white">
                            <FaPlusSquare size={18} />
                            <span className="ml-0">Add Adhoc Checklist Item</span>
                        </span>
                    </button>
                </div>
                <List<any>
                    data={Object.keys(filteredProjectAdHocUnitItem)}
                    renderChildItem={(project: number) => () => {
                        return (ProjectAdHocUnitItem[project].length != 0 ?
                            <div className={`flex items-center`}>
                                <IoBusinessOutline className="w-10 h-10 text-uniteOrange" />
                                <div className="ml-2">
                                    <h2>{getProjectText(projects.find(p => p.projectNumber === Number(project)))}</h2>
                                    <span>{ProjectAdHocUnitItem[project].length} Created AdHoc Items</span>
                                </div>
                            </div> : '')

                    }}
                    onClick={(project) => navigate(`/AdHocUnits/${project}`)}
                    isItemDisabled={false}
                />
            </div>
        </>
    );
}
